.previewContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    perspective: 800px;
}

.image {
    width: 100%;
    box-shadow: black 0 0 10px;
    border-radius: 13px;
    max-width: 300px;
}
